/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const jsonLD = [
    {
      "@context": "http://schema.org/",
      "@type": "AggregateRating",
      "itemReviewed": {
        "name": "Custom Software Solutions",
        "@type": "Organization"
      },
      "ratingCount": "13",
      "bestRating": "5",
      "ratingValue": "5",
      "worstRating": "4.5"
    },
    {
      "@context": "http://www.schema.org",
      "@type": "Organization",
      "name": "CodeWave",
      "url": "https://codewave.eu",
      "sameAs": [
        "https://github.com/cdwv",
        "https://www.facebook.com/codewave.eu/",
        "https://www.linkedin.com/company/codewave-sc/",
        "https://clutch.co/profile/codewave"
      ],
      "logo": "https://codewave.eu/image/1920x0/a382dd18-6764-11e9-85ee-ae585b974e04.png",
      "description": "Engineering scalable web solutions",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "16192 Coastal Hwy",
        "addressLocality": "Lewes",
        "addressRegion": "DE",
        "postalCode": "19958",
        "addressCountry": "United States"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+1(631) 909 5771",
        "contactType": "customer service"
      }
    },
    {
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      "name": "CodeWave",
      "image": {
        "@type": "ImageObject",
        "url": "https://codewave.eu/image/1920x0/a382dd18-6764-11e9-85ee-ae585b974e04.png"
      },
      "priceRange": "Contact Us",
      "telephone": "+1(631) 909 5771",
      "address": "16192 Coastal Hwy, Lewes DE 19958, United States",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingCount": "12",
        "bestRating": "5",
        "ratingValue": "5",
        "worstRating": "4.5"
      }
    }
  ];

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {jsonLD && jsonLD.map((entry, index) => <script key={index} type='application/ld+json'>{JSON.stringify(entry, null, 2)}</script>)}
      
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
